var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-create-mfa",
      "title": _vm.$t('myAccount.mfa.create.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "hide-footer": "",
      "lazy": "",
      "size": "lg",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    on: {
      "show": _vm.showHandle
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t('myAccount.mfa.create.type')))]), _c('v-select', {
    attrs: {
      "reduce": function reduce(val) {
        return val;
      },
      "label": "label",
      "options": _vm.typeOptions,
      "clearable": false,
      "disabled": ""
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('small', {
          staticClass: "font-weight-bolder text-truncate"
        }, [_vm._v(_vm._s(data.label))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('small', {
          staticClass: "font-weight-bolder text-truncate"
        }, [_vm._v(_vm._s(data.label))])];
      }
    }]),
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1), !_vm.qrData && !_vm.keyData ? _c('b-col', {
    staticClass: "d-flex-center my-50",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-lg-25",
    attrs: {
      "variant": "info",
      "pill": "",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.createMfa
    }
  }, [_c('span', {
    staticClass: "align-middle"
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.create.title')) + " ")])])], 1) : _vm._e()], 1), _vm.qrData && _vm.keyData ? _c('div', {
    staticClass: "d-flex-center flex-column mt-50 gap-2"
  }, [_c('div', [_c('h3', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.create.instruction.title')) + " ")]), _c('div', {
    staticClass: "px-1 text-justify"
  }, [_c('div', {
    staticClass: "mb-50"
  }, [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.create.instruction.step1Title')) + " ")]), _c('div', {
    staticClass: "font-italic"
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.create.instruction.step1Content')) + " ")])]), _c('div', {
    staticClass: "mb-50"
  }, [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.create.instruction.step2Title')) + " ")]), _c('div', {
    staticClass: "font-italic"
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.create.instruction.step2Content')) + " ")])]), _c('div', {
    staticClass: "d-flex-center flex-column gap-1"
  }, [_c('QrcodeVue', {
    attrs: {
      "value": _vm.qrData,
      "size": "200",
      "level": "M"
    }
  })], 1), _c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('span', {
    staticClass: "text-dark font-medium-2"
  }, [_vm._v("Mã khoá:")]), _c('code', {
    staticClass: "text-dark font-weight-bold font-medium-2"
  }, [_vm._v(_vm._s(_vm.keyData))]), _c('BButton', {
    staticClass: "p-50 rounded-circle",
    attrs: {
      "variant": "flat-primary",
      "disabled": !_vm.keyData || _vm.disabledCopy
    },
    on: {
      "click": _vm.copyKey
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "copy",
      "size": "18",
      "color": "#2FA4E5"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mb-50"
  }, [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.create.instruction.step3Title')) + " ")]), _c('div', {
    staticClass: "font-italic"
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.create.instruction.step3Content')) + " ")])])])]), _c('div', {
    staticClass: "w-100"
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "otp-verify"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-heading-5"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.create.otpVerify')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])])];
      },
      proxy: true
    }], null, false, 301271037)
  }, [_c('b-form-input', {
    attrs: {
      "id": "otp-verify",
      "name": _vm.$t('myAccount.mfa.create.otpVerify'),
      "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.mfa.create.otpVerify')),
      "lazy-formatter": "",
      "formatter": _vm.trimInput
    },
    model: {
      value: _vm.otpVerify,
      callback: function callback($$v) {
        _vm.otpVerify = $$v;
      },
      expression: "otpVerify"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label-for": "name-verify"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-heading-5"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.create.nameVerify')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])])];
      },
      proxy: true
    }], null, false, 3854374577)
  }, [_c('b-form-input', {
    attrs: {
      "id": "name-verify",
      "name": _vm.$t('myAccount.mfa.create.nameVerify'),
      "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.mfa.create.nameVerify')),
      "lazy-formatter": "",
      "formatter": _vm.trimInput
    },
    model: {
      value: _vm.nameVerify,
      callback: function callback($$v) {
        _vm.nameVerify = $$v;
      },
      expression: "nameVerify"
    }
  })], 1), _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-lg-25",
    attrs: {
      "variant": "info",
      "pill": "",
      "disabled": _vm.loading || !_vm.otpVerify || !_vm.nameVerify
    },
    on: {
      "click": _vm.confirmMfa
    }
  }, [_c('span', {
    staticClass: "align-middle"
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.create.confirmMfa')) + " ")])])], 1)], 1)]) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }