<template lang="">
  <b-modal
    id="modal-create-mfa"
    :title="$t('myAccount.mfa.create.title')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    hide-footer
    lazy
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    @show="showHandle"
  >
    <IAmOverlay :loading="loading">
      <div>
        <b-row>
          <b-col
            cols="12"
          >
            <h5>{{ $t('myAccount.mfa.create.type') }}</h5>
            <v-select
              v-model="type"
              :reduce="val => val"
              label="label"
              :options="typeOptions"
              :clearable="false"
              disabled
            >
              <template #option="data">
                <small class="font-weight-bolder text-truncate">{{ data.label }}</small>
              </template>
              <template #selected-option="data">
                <small class="font-weight-bolder text-truncate">{{ data.label }}</small>
              </template>
            </v-select>
          </b-col>
          <b-col
            v-if="!qrData && !keyData"
            cols="12"
            class="d-flex-center my-50"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="mt-lg-25"
              variant="info"
              pill
              :disabled="loading"
              @click="createMfa"
            >
              <span class="align-middle">
                {{ $t('myAccount.mfa.create.title') }}
              </span>
            </b-button>
          </b-col>
        </b-row>

        <div
          v-if="qrData && keyData"
          class="d-flex-center flex-column mt-50 gap-2"
        >
          <div>
            <h3 class="fw-700">
              {{ $t('myAccount.mfa.create.instruction.title') }}
            </h3>
            <div class="px-1 text-justify">
              <div class="mb-50">
                <div class="font-weight-bolder">
                  {{ $t('myAccount.mfa.create.instruction.step1Title') }}
                </div>
                <div class="font-italic">
                  {{ $t('myAccount.mfa.create.instruction.step1Content') }}
                </div>
              </div>
              <div class="mb-50">
                <div class="font-weight-bolder">
                  {{ $t('myAccount.mfa.create.instruction.step2Title') }}
                </div>
                <div class="font-italic">
                  {{ $t('myAccount.mfa.create.instruction.step2Content') }}
                </div>
              </div>

              <div class="d-flex-center flex-column gap-1">
                <!-- <span class="text-dark font-medium-2">
                  Vui lòng quét QR Code hoặc nhập mã khoá và nhập OTP trên Authenticator
                </span> -->
                <QrcodeVue
                  :value="qrData"
                  size="200"
                  level="M"
                />
              </div>
              <div class="d-flex-center gap-1">
                <span class="text-dark font-medium-2">Mã khoá:</span>
                <code class="text-dark font-weight-bold font-medium-2">{{ keyData }}</code>
                <BButton
                  variant="flat-primary"
                  class="p-50 rounded-circle"
                  :disabled="!keyData || disabledCopy"
                  @click="copyKey"
                >
                  <IAmIcon
                    icon="copy"
                    size="18"
                    color="#2FA4E5"
                  />
                </BButton>
              </div>

              <div class="mb-50">
                <div class="font-weight-bolder">
                  {{ $t('myAccount.mfa.create.instruction.step3Title') }}

                </div>
                <div class="font-italic">
                  {{ $t('myAccount.mfa.create.instruction.step3Content') }}
                </div>
              </div>
            </div>
          </div>

          <div class="w-100">
            <b-form-group label-for="otp-verify">
              <template #label>
                <div class="text-heading-5">
                  {{ $t('myAccount.mfa.create.otpVerify') }}
                  <span class="text-danger">(*)</span>
                </div>
              </template>
              <b-form-input
                id="otp-verify"
                v-model="otpVerify"
                :name="$t('myAccount.mfa.create.otpVerify')"
                :placeholder="`${$t('myAccount.enter')} ${$t('myAccount.mfa.create.otpVerify')}`"
                lazy-formatter
                :formatter="trimInput"
              />
            </b-form-group>

            <b-form-group label-for="name-verify">
              <template #label>
                <div class="text-heading-5">
                  {{ $t('myAccount.mfa.create.nameVerify') }}
                  <span class="text-danger">(*)</span>
                </div>
              </template>
              <b-form-input
                id="name-verify"
                v-model="nameVerify"
                :name="$t('myAccount.mfa.create.nameVerify')"
                :placeholder="`${$t('myAccount.enter')} ${$t('myAccount.mfa.create.nameVerify')}`"
                lazy-formatter
                :formatter="trimInput"
              />
            </b-form-group>
            <div class="d-flex-center">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mt-lg-25"
                variant="info"
                pill
                :disabled="loading || !otpVerify || !nameVerify"
                @click="confirmMfa"
              >
                <span class="align-middle">
                  {{ $t('myAccount.mfa.create.confirmMfa') }}
                </span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </IAmOverlay>
  </b-modal>
</template>
<script>
import {
  BModal, BButton, BRow, BCol, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useClipboard } from '@vueuse/core'

import { apiEmployee } from '@/api'

import { trimInput } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    vSelect: () => import('vue-select'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    QrcodeVue: () => import('qrcode.vue'),
  },
  setup(_, { emit }) {
    const { toastSuccess, toastError } = useToast()
    const loading = ref(false)
    const { copy } = useClipboard()

    const typeOptions = ['AUTHENTICATOR', 'EMAIL']
    const type = ref('AUTHENTICATOR')

    const qrData = ref('')
    const keyData = ref('')
    const otpVerify = ref()
    const nameVerify = ref()
    function showHandle() {
      qrData.value = null
      keyData.value = null
      otpVerify.value = null
      nameVerify.value = null
    }

    async function createMfa() {
      loading.value = true
      try {
        const res = await apiEmployee.initMfa({ type: type.value })
        qrData.value = res.qrData
        keyData.value = res.key
      } catch (error) {
        toastError('myAccount.mfa.create.createMfaError')
      } finally {
        loading.value = false
      }
    }

    async function confirmMfa() {
      loading.value = true
      try {
        await apiEmployee.confirmAddMfa({
          type: type.value,
          otp: otpVerify.value,
          name: nameVerify.value,
        })

        toastSuccess('myAccount.mfa.create.createMfaSuccess')
        emit('enableMfaLogin')
        emit('refetchData')
        this.$root.$emit('bv::hide::modal', 'modal-create-mfa')
      } catch (error) {
        toastError('myAccount.mfa.create.verifyMfaError')
      } finally {
        loading.value = false
      }
    }

    const disabledCopy = ref(false)
    function copyKey() {
      disabledCopy.value = true
      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      copy(keyData.value)
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(() => {
          this.$root.toastError('reservation.sms.copyFail')
        })
    }
    return {
      createMfa,
      showHandle,
      typeOptions,
      type,
      loading,
      qrData,
      keyData,
      copyKey,
      disabledCopy,
      confirmMfa,
      trimInput,
      otpVerify,
      nameVerify,
    }
  },
}
</script>
<style lang="">

</style>
